var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"balance-changes-table-container"},[_c('table-component',{staticClass:"balance-changes-table",attrs:{"data":_vm.days,"columns":_vm.columns,"grid-template":_vm.gridTemplate},scopedSlots:_vm._u([{key:"header-date",fn:function(ref){
var column = ref.column;
return [_c('div',{staticClass:"info-heading"},[_c('h2',[_vm._v(_vm._s(column.title))])])]}},{key:"header-items",fn:function(ref){
var column = ref.column;
return [_c('div',{staticClass:"info-heading"},[_c('h2',[_vm._v(_vm._s(column.value))]),_c('p',[_vm._v(_vm._s(column.title))])])]}},{key:"header-deposited",fn:function(ref){
var column = ref.column;
return [_c('div',{staticClass:"info-heading"},[_c('h2',[_vm._v(_vm._s(column.value))]),_c('p',[_vm._v(_vm._s(column.title))])])]}},{key:"header-spent",fn:function(ref){
var column = ref.column;
return [_c('div',{staticClass:"info-heading"},[_c('h2',[_vm._v(" "+_vm._s(column.value)+" "),_c('span',[_vm._v(_vm._s(column.tip))])]),_c('p',[_vm._v(_vm._s(column.title))])])]}},{key:"row-date",fn:function(props){return [_c('h3',[_vm._v(_vm._s(_vm.getDateString(props).format("MMM DD")))])]}},{key:"row-details",fn:function(props){return [_c('router-link',{attrs:{"to":_vm.getDetailsLink(props)}},[_vm._v(" Details ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }