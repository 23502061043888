<template>
  <div class="gateways-view">
    <moe-block
      heading="Gateway groups"
      :loading="genericLoading"
    >
      <template #heading>
        <search-input
          ref="searchInput"
          class="search"
          @search="onSearch"
        />
      </template>
      <template #actions>
        <router-link :to="{ name: 'gateway-group-create' }">
          <button-input :primary="false">
            <plus-icon
              slot="icon"
              fill="currentColor"
            />
            <span>Add gateway group</span>
          </button-input>
        </router-link>
      </template>

      <template #content>
        <div class="gateways-container">
          <div class="fixed-container">
            <Map
              v-bind="mapSettings"
              units="meters"
              :access-token="map.accessToken"
              :style-id="map.styleId"
              :mapbox-user="map.mapboxUser"
            />
          </div>

          <table-component
            class="gateway-groups-table"
            v-bind="table"
            @sort-by="onSort"
            @hit-bottom="onHitBottom"
          >
            <template #row-status="{ item }">
              <switch-input
                v-model="item.status"
                @input="onGatewaGroupSwitch(item)"
              />
            </template>

            <template #row-name="{ item }">
              <FlexChildrenOverflowedText>
                <template #default>
                  {{ item.name }}
                </template>

                <template #content>
                  {{ item.name }}
                </template>
              </FlexChildrenOverflowedText>
            </template>

            <template #row-data="{ item }">
              <inline-chart
                v-if="chartData[item.id]"
                :chart-data="chartData[item.id]"
              />
            </template>

            <template #row-updated_at="{ item }">
              <span>{{ formatDate(item.updated_at) }}</span>
            </template>

            <template #row-group_type="{ item }">
              {{ getGateGroupType(item.group_type) }}
            </template>

            <template #row-action="{ item }">
              <button-input
                class="stretched"
                :primary="true"
                @click.native="onConfigureClick(item)"
              >
                Configure
              </button-input>
            </template>
          </table-component>
        </div>
      </template>
    </moe-block>
  </div>
</template>

<script>
import Moment from 'moment';

import { mapActions, mapGetters, mapMutations } from 'vuex';
import { mixin as tableMixin } from './mixins/table';

import PlusIcon from '@/components/Icons/Plus';
import InlineChart from '@/components/Charts/InlineChart';
import FlexChildrenOverflowedText from '@/views/Devices/FlexChildrenOverflowedText'

// const Map = () => import(/* webpackPrefetch: true */ "@/components/MapLeaflet");

const Map = () => import(/* webpackPrefetch: true */ '@/components/Map');

import { Table as TableComponent } from '@moeco-platform/moeco-components';

import GatewaysApi from '@/api/gateways';

export default {
  name: 'GatewayGroupsView',

  components: {
    PlusIcon,
    TableComponent,
    Map,
    InlineChart,
    FlexChildrenOverflowedText
    // TagWrapper,
  },

  mixins: [tableMixin],

  data() {
    return {
      allGateways: [],

      map: {
        accessToken: process.env.VUE_APP_MAPBOX_API_KEY,
        styleId: process.env.VUE_APP_MAPBOX_STYLE_ID,
        mapboxUser: process.env.VUE_APP_MAPBOX_USER,
      },

      mapSettings: {
        gateways: [],
        accessToken: process.env.VUE_APP_MAPBOX_API_KEY,
        'map-options': {
          style: process.env.VUE_APP_MAPBOX_STYLE,
        },
        'nav-control': { show: true, position: 'bottom-right' },
      },
    };
  },
  computed: {
    ...mapGetters({
      gatewaysList: 'gateways/list',
      list: 'gatewayGroups/list',
      chartData: 'deviceGroups/chartData',
      listLoading: 'gatewayGroups/loading',
      itemLoading: 'gatewayGroup/loading',
      settings: 'constants/settings',
    }),

    genericLoading: function () {
      return this.listLoading || this.itemLoading;
    },
  },
  watch: {
    list: {
      immediate: true,
      handler() {
        this.table.data = this.normalizeData(this.list);
      },
    },
  },

  async activated() {

    this.fetch();

    // const gatewaysList = await this.fetchAllGateways();
    // const prepared = gatewaysList
    //   .map((rawGateItem) => {
    //     const { longitude, latitude, id, range } = rawGateItem;
    //     if (longitude && latitude && range) {
    //       return {
    //         id,
    //         coords: [latitude, longitude],
    //         radius: range,
    //       };
    //     }
    //   })
    //   .filter((item) => item);
    // this.mapSettings.gateways = prepared;
  },

  deactivated() {
    this.$refs.searchInput.clear()
  },


  methods: {
    ...mapActions({
      gatewaysChangeParams: 'gateways/changeParams',
      getGatewaysList: 'gateways/getList',
      updateGatewayGroup: 'gatewayGroup/updateItem',
      changeParams: 'gatewayGroups/changeParams',
      getLazyList: 'gatewayGroups/getLazyList',
      resetLazyList: 'gatewayGroups/resetLazyList',
    }),

    ...mapMutations({ resetLazyList: 'gatewayGroups/resetLazyList' }),

    async onSearch(term) {
      this.resetLazyList()
      if (typeof term === 'string') {
        this.changeParams({ term: term.toLowerCase().trim() });
      }
      this.fetch();
    },


    async fetchAllGateways() {
      const { data, meta } = await GatewaysApi.getList({
        orderBy: { value: 'id', desc: false },
      });

      this.allGateways = data;

      const portions = Math.round(meta.total / 100) - 1;

      let promises = [];

      for (let i = 1; i <= portions; i++) {
        const p = GatewaysApi.getList({
          limit: {
            offset: i * 100,
          },
          orderBy: { value: 'id', desc: false },
        }).then(({ data }) => {
          this.allGateways = [...this.allGateways, ...data];
        });

        promises.push(p);
      }

      await Promise.all(promises);

      return this.allGateways;
    },

    async fetch() {
      try {
        // await this.getList();
        await this.getLazyList();
      } catch (error) {
        this.$toast.error(error, {
          position: 'top-left',
          duration: 6000,
        });
      }
    },


    onHitBottom() {
      this.fetch()
    },


    onConfigureClick(item) {
      this.$router.push({
        name: 'gateway-group-gateways',
        params: { id: item.blockchain_uid },
      });
    },

    async onGatewaGroupSwitch(gatewayGroup) {
      try {
        const copy = JSON.parse(JSON.stringify(gatewayGroup));

        const groupType = copy.group_type;
        const { typesById } = this.settings.gatewayGroups;
        const groupTypeName = typesById[groupType].name;
        copy.group_type = groupTypeName;

        await this.updateGatewayGroup(copy);
        await this.getLazyList();
      } catch (error) {
        this.$toast.error(error, {
          position: 'top-left',
          duration: 6000,
        });
      }
    },

    onSort(value) {
      this.changeParams({ orderBy: { value } });
      this.fetch();
    },

    formatDate(date) {
      return Moment(date).format('DD/MM/YYYY');
    },

    normalizeData(list) {
      return list.map((item) => ({
        ...item,
        status: item.status ? true : false,
      }));
    },

    getGateGroupType(gateGroupId) {
      if (!gateGroupId) return 'Wrong type';

      if (!this.settings) return null;

      const { types } = this.settings.gatewayGroups;

      let gateGroupType = Object.entries(types).find(
        (item) => item[1].id == gateGroupId
      );

      return gateGroupType
        ? Object.entries(gateGroupType)[1][1].name
        : 'Wrong type';
    },
  },
};
</script>

<style lang="scss" scoped>
.gateways-view {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;


  .search {
    min-width: 150px;
    max-width: 450px;
  }


  .gateways-container {
    display: flex;
    flex-direction: column;
    // flex: 1;
    height: 100%;

    .fixed-container {
      flex-basis: 40%;
      display: flex;
      flex-direction: column;
    }

    .gateway-groups-table {
      // width: min-content;
      margin-top: 20px;
      padding-right: 15px;
      flex-basis: 60%;

      .flex {
        display: flex;
        flex: 1;
        text-decoration: none;

        .stretched {
          flex: 1;
        }
      }
    }
  }
}
</style>
