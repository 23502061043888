var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"gateways-view"},[_c('moe-block',{attrs:{"heading":"Gateway groups","loading":_vm.genericLoading},scopedSlots:_vm._u([{key:"heading",fn:function(){return [_c('search-input',{ref:"searchInput",staticClass:"search",on:{"search":_vm.onSearch}})]},proxy:true},{key:"actions",fn:function(){return [_c('router-link',{attrs:{"to":{ name: 'gateway-group-create' }}},[_c('button-input',{attrs:{"primary":false}},[_c('plus-icon',{attrs:{"slot":"icon","fill":"currentColor"},slot:"icon"}),_c('span',[_vm._v("Add gateway group")])],1)],1)]},proxy:true},{key:"content",fn:function(){return [_c('div',{staticClass:"gateways-container"},[_c('div',{staticClass:"fixed-container"},[_c('Map',_vm._b({attrs:{"units":"meters","access-token":_vm.map.accessToken,"style-id":_vm.map.styleId,"mapbox-user":_vm.map.mapboxUser}},'Map',_vm.mapSettings,false))],1),_c('table-component',_vm._b({staticClass:"gateway-groups-table",on:{"sort-by":_vm.onSort,"hit-bottom":_vm.onHitBottom},scopedSlots:_vm._u([{key:"row-status",fn:function(ref){
var item = ref.item;
return [_c('switch-input',{on:{"input":function($event){return _vm.onGatewaGroupSwitch(item)}},model:{value:(item.status),callback:function ($$v) {_vm.$set(item, "status", $$v)},expression:"item.status"}})]}},{key:"row-name",fn:function(ref){
var item = ref.item;
return [_c('FlexChildrenOverflowedText',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_vm._v(" "+_vm._s(item.name)+" ")]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(item.name)+" ")]},proxy:true}],null,true)})]}},{key:"row-data",fn:function(ref){
var item = ref.item;
return [(_vm.chartData[item.id])?_c('inline-chart',{attrs:{"chart-data":_vm.chartData[item.id]}}):_vm._e()]}},{key:"row-updated_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.updated_at)))])]}},{key:"row-group_type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getGateGroupType(item.group_type))+" ")]}},{key:"row-action",fn:function(ref){
var item = ref.item;
return [_c('button-input',{staticClass:"stretched",attrs:{"primary":true},nativeOn:{"click":function($event){return _vm.onConfigureClick(item)}}},[_vm._v(" Configure ")])]}}])},'table-component',_vm.table,false))],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }